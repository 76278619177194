<template>
  <div>
    <div>
      <b-flix-card>
        <div slot="title">
          <b-flix-alert variant="info">
            {{
              $t('message.add', { name: $tc('message.appointments', 1) })
            }}</b-flix-alert
          >
        </div>
        <p class="flix-html-small" v-html="getInfo" />
      </b-flix-card>
      <calendarBreadcrumbs :link="link" />
    </div>
    <div>
      <transition name="flixFadeIn">
        <addCustomer :key="updateKey" :id="assistent.ID" />
      </transition>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    calendarBreadcrumbs() {
      return import('@/components/assets/calendarBreadcrumbs')
    },
    addCustomer() {
      return import('@/components/crm/addCustomer/index')
    }
  },
  props: {},
  data() {
    return {
      updateKey: new Date().getTime(),
      assistent: false,
      link: 'dashboardAddCustomer'
    }
  },
  watch: {
    '$route.params.id'() {
      this.updateKey = new Date().getTime()
      this.get_assistent()
    }
  },
  methods: {
    get_assistent() {
      this.assistent = false
      Object.keys(this.$store.getters.assistents).forEach(
        function (key) {
          if (
            this.$store.getters.assistents[key].ID === this.$route.params.id
          ) {
            this.assistent = this.$store.getters.assistents[key]
          }
        }.bind(this)
      )
    }
  },
  mounted() {
    this.get_assistent()
    if (this.$getUserVariables().state === 'share') {
      this.link = 'sharesAddCustomer'
    }
  },
  computed: {
    getInfo() {
      return (
        this.$t('message.customerInfo').split('\n').join('<br>') +
        '<br><br>- ' +
        this.$t('message.customerByUser', {
          icon: '<i class="flix-glyphicon icon flix-glyphicon-pen"></i>'
        })
      )
    }
  }
}
</script>
<style lang="sass" scoped></style>
